import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import backgroundVector from 'src/assets/images/enterprise-vector.png'
import { Button } from "src/templates"
import TextField from '@mui/material/TextField';

const EnterpriseForm = ({title, body, image, backgroundColor}) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))
 const md = useMediaQuery(theme.breakpoints.down('md'))
   
 const styles = {
  paper: {
   border: `2px solid ${theme.palette.primary.main}`,
   borderRadius: "20px",
   minWidth: "320px",
   maxWidth: lg ? "none" : "465px",
   width: lg ? "100%" : "auto",
   background: backgroundColor ? "white" : "transparent"
  },
  allyFormBackground: {
   padding: "40px 0px",
   background: backgroundColor ? `${theme.palette.primary.main}20` : "white",
  },
  title: {
   display: 'inline-block',
  },
  allyFormContainer: {
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   flexDirection: !lg ? 'row' : 'column',
   rowGap: 5,
   columnGap: "50px",
  },
  allyFormGraphic: {
   width: !lg ? '42.5%' : '100%',
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'center',
   alignItems: "center",
   columnGap: 2,
  },
  imageContainer: {
   width: "min-content",
  },
  rightImageContainer: {
   '&::before': {
    display: 'block',
    position: 'absolute',
    content: "' '",
    backgroundImage: `url(${backgroundVector})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: "300px",
    height: "80px",
    zIndex: -1,
    marginLeft: "-200px",
   }
  },
  image: {
   width: "100%",
   maxWidth: lg ? "480px" : "auto",
   minWidth: "172px",
  },
  leftImage: {
   marginTop: "200px",
  },
 }
 
 return (
  <Box sx={styles.allyFormBackground}>
   <Container sx={styles.allyFormContainer}>
    {/* Left side */}
    <Box sx={styles.allyFormGraphic}>
     <Box component="img" sx={styles.image} src={image} alt=""/>
    </Box>
    {/* Right side */}
    <Box sx={styles.paper}>
     <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ display: "flex", flexDirection: "column", height: 500, padding: md ? "24px" : "48px 64px", justifyContent: "space-between"}}
     >
      <Typography variant="h3" color="primary.dark" sx={styles.title}>{title}</Typography>
      <Typography variant="body1">{body}</Typography>
      <TextField id="outlined-basic" label="Nombre" variant="outlined"
       InputLabelProps={{
        style: { color: theme.palette.text.primary },
       }}
      />
      <TextField id="outlined-basic" label="Correo electrónico" variant="outlined"
       InputLabelProps={{
        style: { color: theme.palette.text.primary },
       }}
      />
      <TextField id="outlined-basic" label="Teléfono de contacto" variant="outlined"
       InputLabelProps={{
        style: { color: theme.palette.text.primary },
       }}
      />
      <Box sx={{ paddingTop: "21px", margin: "0 auto" }}>
       <Button variant={"contained"}>Enviar datos</Button>
      </Box>
     </Box>
    </Box>
   </Container>
  </Box>
 )}

export { EnterpriseForm }
import React from "react"
import { HeroSmall } from "src/templates"
import { EnterpriseForm } from "src/components/common/EnterpriseForm"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EnterprisesBenefits } from "src/components/common/EnterprisesBenefits";
import { Enterprises } from "src/components/common/Enterprises"

// Imports para los contenidos
import { getContent } from 'src/queries';

// Imports para el carrusel de empresas que forman parte de nuestra red

import ColbunLogo from "src/assets/svg/logos/enterprises/colbun.svg";
import AustralisLogo from "src/assets/svg/logos/enterprises/australis.svg";
import PakLogo from "src/assets/svg/logos/enterprises/parqueArauco.svg";
import MollerLogo from "src/assets/svg/logos/enterprises/moller.svg";
import SoproleLogo from "src/assets/svg/logos/enterprises/soprole.svg";
import SodimacLogo from "src/assets/svg/logos/enterprises/sodimac.svg";
import WomLogo from "src/assets/svg/logos/enterprises/wom.svg";
import TottusLogo from "src/assets/svg/logos/enterprises/tottus.svg";

import HowDoesItWork from "src/assets/images/faq-ciclo.png";

export default function Corporaciones() {

 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const enterprisesItems
 = [
  {
   logo: ColbunLogo,
  },
  {
   logo: PakLogo,
  },
  {
   logo: TottusLogo,
  },
  {
   logo: SoproleLogo,
  },
  {
   logo: SodimacLogo,
  },
  {
   logo: MollerLogo,
  },
  {
   logo: AustralisLogo,
  },
  {
   logo: WomLogo,
  },
 ]

 const heroEnterprisesBannerResults = getContent("hero-enterprises-banner", process.env.GATSBY_REACT_APP_REGION)
 const benefitsEnterprisesFeaturesResults = getContent("benefits-enterprises-features", process.env.GATSBY_REACT_APP_REGION)
 const allyFormContentAreaResults = getContent("ally-form-content-area", process.env.GATSBY_REACT_APP_REGION)
 const howToEnterprisesFeaturesResults = getContent("how-to-enterprises-features", process.env.GATSBY_REACT_APP_REGION)

 const colorPalette = [
  theme.palette.primary.light,
  theme.palette.primary.main,
  theme.palette.primary.dark,
 ]
 
 return (
  <>
   {process.env.GATSBY_REACT_APP_REGION !== 'en-US' && (
    <>
     {/* Bloque de video o imagen principal */}
     <HeroSmall
      title={heroEnterprisesBannerResults?.title}
      body={heroEnterprisesBannerResults?.body?.internal?.content}
      backgroundDesktop={heroEnterprisesBannerResults?.backgroundDesktop?.file?.url}
      backgroundMobile={heroEnterprisesBannerResults?.backgroundMobile?.file?.url}
     />
     {/* Bloque de ¿Cómo funciona? */}
     <Box style={{ padding: "40px 0px"}}>
      <Container>
       <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: lg ? "column" : "row", columnGap: "80px", maxWidth: "1440px", margin: "0 auto", rowGap: "80px" }}>
        <Box sx={{ maxWidth: "515px" }}>
         <Box component="img" src={HowDoesItWork} sx={{ width: "100%"}} />
        </Box>
        <Box sx={{ maxWidth: "515px" }}>
         <Box sx={{ height: "fit-content"}}>
          <Typography align={lg ? "center" : "left"} variant="h3" color="primary.dark" >¿Cómo funciona?</Typography>
         </Box>
         <Grid container justifyContent="flexStart" style={{ position: 'relative', bottom: 0, width: '100%', padding: "35px 0px 0", maxWidth: 850, margin: "0 auto", display: "flex"}}>
          {howToEnterprisesFeaturesResults?.features.map((item, key) => (
           <Grid item sx={{ display: "flex", alignItems: "center", marginBottom: "32px" }} key={key} >
            <Box sx={{ boxShadow: "0px 10px 4px rgb(0 0 0 / 10%)", marginRight: 4, width: 40, height: 40, background: colorPalette[key], borderRadius: '100%', display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
             <Typography align="center" sx={{ width: 40 }} variant="h3" color={theme.palette.text.secondary}>{item?.number}</Typography>
            </Box>
            <Typography>{item?.title}</Typography>
           </Grid>
          ))}
         </Grid>
        </Box>
       </Box>
      </Container>
     </Box>
     {/* Bloque de beneficios de ProntoPago */}
     <EnterprisesBenefits
      title={benefitsEnterprisesFeaturesResults?.title}
      items={benefitsEnterprisesFeaturesResults?.features}
     />
     {/* Bloque de empresas que forman parte de la red */}
     <Enterprises
      title="Somos la solución tecnológica de más de 130 empresas en Latinoamérica"
      variant="variant2"
      items={enterprisesItems}/>
     {/* Bloque de formulario */}
     <EnterpriseForm
      title={allyFormContentAreaResults?.title}
      body={allyFormContentAreaResults?.body?.internal?.content}
      image={allyFormContentAreaResults?.images[0]?.file?.url}
      items={allyFormContentAreaResults?.icons}
     />
    </>
   )}
  </>
 )
}

import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Icon } from "src/templates"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const EnterprisesBenefits = ({title, items}) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const styles = {
  container: {
   padding: "40px 0px",
   display: 'flex',
   flexDirection: "column",
   justifyContent: 'center',
   background: `${theme.palette.primary.main}20`,
  },
  titleContainer: {
   textAlign: "center",
   padding: "40px 0px",
  },
  solutionsFeaturesColumn: {
   width: lg ? "100%" : "50%",
   borderBottom: lg ? `2px solid ${theme.palette.primary.dark}` : 0,
   paddingBottom: 0,
   borderRight: !lg ? `2px solid ${theme.palette.primary.dark}` : 0,
   paddingRight: !lg ? 8 : 0,
   "&:last-child": {
    border: 'none',
    paddingLeft: !lg ? 8 : 0,
    paddingRight: 0,
    paddingTop: !lg ? 0 : 4,
    paddingBottom: 0,
   }
  },
  solutionsFeaturesColumnTitle: {
   paddingBottom: 5,
  },
  solutionsFeaturesContainer: {
   display: 'flex',
   flexDirection: lg ? "column" : "row",
   maxWidth: "1144px",
   margin: "0 auto",
  },
  solutionsFeatureItem: {
   display: 'flex',
   flexDirection: 'row',
   width: '100%',
   minHeight: "auto",
  },
  solutionsFeatureIcon: {
   width: 50,
   height: 50,
   marginRight: 2,
  },
  solutionsFeatureTitle: {
   marginBottom: 4,
  },
 }
 
 return (
  <Container sx={styles.container}>
   <Box sx={styles.titleContainer}>
    <Typography variant="h3" color="primary.dark">{title}</Typography>
   </Box>
   <Box sx={styles.solutionsFeaturesContainer}>
    <Box sx={styles.solutionsFeaturesColumn}>
     <Typography sx={styles.solutionsFeaturesColumnTitle} variant="h4" color="primary.dark">Para corporaciones:</Typography>
     <Box>
      {items.slice(0, 3)?.map((solutionsFeature, index) => (
       <Box key={index} sx={styles.solutionsFeatureItem}>
        <Box sx={styles.solutionsFeatureIcon}>
         <Icon name={solutionsFeature.icon} color={theme.palette.primary.main} height="32px" />
        </Box>
        <Box sx={styles.solutionsFeatureTitle}>
         <Typography color="text.primary" display="inline">{solutionsFeature?.description}</Typography>
        </Box>
        {solutionsFeature?.body && (
         <Box sx={styles.solutionsFeatureDescription}>
          <Typography color="text.primary">
           <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(solutionsFeature?.body?.raw)) }} />
          </Typography>
         </Box>
        )}
       </Box>
      ))}
     </Box>
    </Box>
    <Box sx={styles.solutionsFeaturesColumn}>
     <Typography sx={styles.solutionsFeaturesColumnTitle} variant="h4" color="primary.dark">Para empresas proveedoras:</Typography>
     <Box>
      {items.slice(3, 6)?.map((solutionsFeature, index) => (
       <Box key={index} sx={styles.solutionsFeatureItem}>
        <Box sx={styles.solutionsFeatureIcon}>
         <Icon name={solutionsFeature?.icon} color={theme.palette.primary.main} height="32px" />
        </Box>
        <Box sx={styles.solutionsFeatureTitle}>
         <Typography color="text.primary" display="inline">{solutionsFeature?.description}</Typography>
        </Box>
        {solutionsFeature?.body && (
         <Box sx={styles.solutionsFeatureDescription}>
          <Typography color="text.primary">
           <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(solutionsFeature?.body?.raw)) }} />
          </Typography>
         </Box>
        )}
       </Box>
      ))}
     </Box>
    </Box>
   </Box>
  </Container>
 )}

export { EnterprisesBenefits }